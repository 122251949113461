import { ReactElement, useState } from "react";
import { useHistory } from "react-router";
import { Tenant, MenuItem } from "@citydelivery/business-logic";

import MenuSectionTable from "./components/MenuSectionTable";
import ShoppingCart from "./components/ShoppingCart";

interface Props {
  tenant: Tenant;
}

const days = ["ma", "di", "wo", "do", "vr", "za", "zo"];

export default function MenuPage({ tenant }: Props): ReactElement {
  const items: MenuItem[] = tenant.menu.sections.reduce(
    (itemArray, section) => itemArray.concat(section.items),
    [] as MenuItem[],
  );
  const obj: { [key: string]: { [tierId: string]: number } } = {};
  const itemsInitialState = items.reduce((_, item) => {
    obj[item.id] = {};
    return obj;
  }, {});
  const [shoppingCart, setShoppingCart] = useState<{
    [itemId: string]: { [tierId: string]: number };
  }>(itemsInitialState);
  const imgPrefix = `${process.env.REACT_APP_CONTENT_BASE_URL}/${tenant.tenantId}`;

  const history = useHistory();

  return (
    <div className="pb-32">
      <img className="w-full" src={`${imgPrefix}/${tenant.images.banner}`} alt={tenant.name} />
      <div className="mt-16 md:px-16">
        <div className="flex flex-col mb-16 text-center">
          <h1 className="text-4xl font-semibold mb-2">{tenant.name}</h1>
          <p className="flex flex-row mx-auto">
            {tenant.address}
            {tenant.website && (
              <>
                <span> | </span>
                <a
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600 hover:underline"
                  rel="noreferrer"
                  href={tenant.website}
                >
                  {tenant.website}
                </a>
              </>
            )}
            {tenant.phoneNumber && ` | ${tenant.phoneNumber}`}
            {tenant.socials?.facebook && (
              <a
                className="h-6 w-6"
                style={{ marginLeft: 10, position: "relative", top: -2 }}
                href={tenant.socials?.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <img className="h-6 w-6" src="/assets/facebook.png" alt="FB" />
              </a>
            )}
            {tenant.socials?.instagram && (
              <a
                className="h-6 w-6"
                style={{ marginLeft: 10, position: "relative", top: -2 }}
                href={tenant.socials?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <img className="h-6 w-6" src="/assets/instagram.png" alt="insta" />
              </a>
            )}
          </p>
          <small>
            <strong>Openingstijden: </strong>
            {tenant.openingHours
              .map((openingHour, index) => {
                if (openingHour.from === openingHour.to) {
                  return null;
                }
                return `${days[index]}: ${openingHour.from} - ${openingHour.to}`;
              })
              .filter((h) => h !== null)
              .join(", ")}
          </small>
        </div>
        {tenant.menu.sections.map((section) => (
          <MenuSectionTable
            tenant={tenant}
            shoppingCart={shoppingCart}
            key={section.title}
            section={section}
            onRemoveFromCart={(itemId: string, tierId?: string) => {
              setShoppingCart({
                ...shoppingCart,
                [itemId]: {
                  ...shoppingCart[itemId],
                  [tierId ?? itemId]: (shoppingCart[itemId][tierId ?? itemId] ?? 0) - 1,
                },
              });
            }}
            onAddToCart={(itemId: string, tierId?: string) => {
              setShoppingCart({
                ...shoppingCart,
                [itemId]: {
                  ...shoppingCart[itemId],
                  [tierId ?? itemId]: (shoppingCart[itemId][tierId ?? itemId] ?? 0) + 1,
                },
              });
            }}
          />
        ))}
        <div className="mt-16">
          <ShoppingCart
            tenant={tenant}
            onOrderPlaced={() => {
              setShoppingCart(itemsInitialState);
              history.push("/order-succesful");
            }}
            items={items}
            shoppingCart={shoppingCart}
          />
        </div>
      </div>
    </div>
  );
}
