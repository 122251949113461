import { ReactElement } from "react";
import { Tenant, MenuItem, OrderRow, formatPrice, calculateTotal } from "@citydelivery/business-logic";
import OrderForm from "./OrderForm";

interface Props {
  tenant: Tenant;
  items: MenuItem[];
  shoppingCart: { [itemId: string]: { [tierId: string]: number } };
  onOrderPlaced: () => void;
}

interface ShoppingCartItemProps {
  orderRow: OrderRow;
  index: number;
}

function getOrderFromShoppingCart(
  items: MenuItem[],
  shoppingCart: { [itemId: string]: { [tierId: string]: number } },
): OrderRow[] {
  const itemIds = Object.keys(shoppingCart).filter((id) => Object.values(shoppingCart[id]).length !== 0);
  return itemIds
    .flatMap((itemId) => {
      const item = items.find((i) => i.id === itemId)!;
      const amounts = shoppingCart[itemId];

      if (item.price.type === "tiers") {
        const tierIds = Object.keys(amounts);
        return tierIds
          .filter((id) => amounts[id])
          .map((tierId) => {
            const tier =
              item.price.type === "tiers"
                ? item.price.tiers.find((t: { amount: number; title: string; id: string }) => t.id === tierId)
                : undefined;
            if (tier === undefined) {
              throw new Error("Tier is undefined");
            }
            const tierCount = amounts[tierId];

            return {
              itemId,
              tierId: tier.id,
              title: `${item.title} ${tier.title}`,
              amount: amounts[tier.id],
              price: tier.amount,
              subtotal: tierCount * tier.amount,
            };
          });
      }
      return {
        itemId,
        title: item.title,
        amount: amounts[itemId],
        price: item.price.amount,
        subtotal: amounts[itemId] * item.price.amount,
      };
    })
    .filter((row) => row.amount !== 0);
}

function ShoppingCartItem({ orderRow, index }: ShoppingCartItemProps) {
  return (
    <tr className={`${index % 2 === 1 ? "bg-gray-50" : ""}`}>
      <td className="p-3">{orderRow.title}</td>
      <td className="p-3">{orderRow.amount}</td>
      <td className="p-3 min-w-5 text-right">{formatPrice(orderRow.price)}</td>
      <td className="p-3 min-w-5 text-right">{formatPrice(orderRow.subtotal)}</td>
    </tr>
  );
}

export default function ShoppingCart({ items, tenant, shoppingCart, onOrderPlaced }: Props): ReactElement | null {
  const orderFromShoppingCart = getOrderFromShoppingCart(items, shoppingCart);
  const total = calculateTotal(orderFromShoppingCart);
  if (total === 0) {
    return null;
  }

  return (
    <div className="shadow rounded p-5">
      <h3 className="text-2xl mb-3">Bestelling</h3>
      <table className="table-auto w-full text-left mb-16">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-3">Product</th>
            <th className="p-3">#</th>
            <th className="p-3 min-w-5 text-right">Prijs</th>
            <th className="p-3 min-w-5 text-right">Totaal</th>
          </tr>
        </thead>
        <tbody>
          {orderFromShoppingCart.map((row, index) => (
            <ShoppingCartItem key={`${row.itemId}${row.tierId}`} index={index} orderRow={row} />
          ))}
        </tbody>
        <tfoot>
          <tr className="bg-gray-100">
            <th className="p-3" colSpan={3}>
              Totaal
            </th>
            <th className="p-3 min-w-5 text-right">{formatPrice(total)}</th>
          </tr>
        </tfoot>
      </table>
      <OrderForm tenant={tenant} totalOrderValue={total} onOrderPlaced={onOrderPlaced} order={orderFromShoppingCart} />
    </div>
  );
}
