import { ReactElement } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import axios from "axios";
import { BrowserRouter, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Tenant } from "@citydelivery/business-logic";
import MenuPage from "./MenuPage";
import SuccessPage from "./SuccessPage";

const getTenant = async () => {
  const host = process.env.REACT_APP_HOST_OVERRIDE ?? window.location.host.split(".")[0];
  const response = await axios.get<Tenant>(`${process.env.REACT_APP_API_URL}/tenants/${host}`);
  return response.data;
};

function App(): ReactElement | null {
  const { data: tenant, isLoading, error } = useQuery("tenant", getTenant);

  if (isLoading || error || tenant === undefined) {
    return null;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{tenant.name} | Bestellen In Breda</title>
        <meta name="description" content={tenant.descriptions.subtitle} />
      </Helmet>
      <BrowserRouter>
        <Route path="/order-succesful" exact>
          <SuccessPage tenant={tenant} />
        </Route>
        <Route path="/">
          <div className="container mx-auto xl:px-52">
            <MenuPage tenant={tenant} />
          </div>
          <div className="bg-gray-100 w-full h-32" />
        </Route>
      </BrowserRouter>
    </>
  );
}
const queryClient = new QueryClient();
const WrappedApp = () => (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

export default WrappedApp;
