import { Type } from "class-transformer";
import {
  IsEmail,
  IsInt,
  IsOptional,
  ValidateNested,
  IsString,
  Min,
  IsBoolean,
  IsArray,
  ArrayMinSize,
  ArrayMaxSize,
  IsUrl,
  Length,
} from "class-validator";
import { Menu } from "./menu";

class DeliveryMethods {
  @IsBoolean()
  pickup: boolean;
  @IsBoolean()
  deliver: boolean;
}

class Socials {
  @IsOptional()
  instagram: boolean;
  @IsOptional()
  facebook: boolean;
}

class Descriptions {
  @Length(3, 1000)
  closed: string;

  @Length(3, 1000)
  subtitle: string;

  @Length(3, 1000)
  order: string;
}

class OrderRecipients {
  @IsArray()
  @ArrayMinSize(1)
  @IsEmail({}, { each: true })
  emailAddresses: string[];
}

export class Images {
  @Length(4, 250)
  logo: string;

  @Length(4, 250)
  banner: string;
}

export class Tenant {
  @IsString()
  tenantId: string;

  @Length(3, 250)
  address: string;

  @Length(3, 250)
  name: string;

  @IsOptional()
  @IsInt()
  @Min(0)
  minimumOrderAmount?: number;

  @ValidateNested()
  @Type(() => Descriptions)
  descriptions: Descriptions;

  @ValidateNested()
  @Type(() => DeliveryMethods)
  deliveryMethods: DeliveryMethods;

  @IsArray()
  @ValidateNested()
  @ArrayMinSize(7)
  @ArrayMaxSize(7)
  openingHours: {
    from: string;
    to: string;
  }[];

  @IsOptional()
  @Length(10, 30)
  phoneNumber?: string;

  @ValidateNested()
  @Type(() => OrderRecipients)
  orderRecipients: OrderRecipients;

  @IsOptional()
  @IsUrl()
  website?: string;

  @ValidateNested()
  @Type(() => Menu)
  menu: Menu;

  @ValidateNested()
  @Type(() => Images)
  images: Images;

  @IsOptional()
  @ValidateNested()
  @Type(() => Socials)
  socials?: {
    facebook?: string;
    instagram?: string;
  };

  @IsOptional()
  @IsBoolean()
  visibleOnMainWebsite?: boolean;

  @IsBoolean()
  canOrder: boolean;
}
