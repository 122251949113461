import { ReactElement, useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { Box } from "reflexbox";
import { addDays, parse, getDay, parseISO, subMinutes } from "date-fns";
import { UseFormMethods } from "react-hook-form";

import nl from "date-fns/locale/nl";

import "react-datepicker/dist/react-datepicker.css";
import "./DeliveryForm.css";
import { OrderFormFields, getClosedDatesUntilDate, formatPrice } from "@citydelivery/business-logic";

registerLocale("nl", nl);
setDefaultLocale("nl");

interface Props {
  openingHours: {
    from: string;
    to: string;
  }[];
  form: UseFormMethods<OrderFormFields>;
  deliveryMethods: {
    pickup: boolean;
    deliver: boolean;
  };
  minimumOrderValueForDelivery: number;
  totalOrderValue: number;
}

function getDeliveryMomentLabel(canPickup: boolean, canDeliver: boolean): string {
  if (canPickup && canDeliver) {
    return "Gewenste bezorg/afhaalmoment";
  }
  if (canPickup) {
    return "Gewenste afhaalmoment";
  }
  if (canDeliver) {
    return "Gewenste bezorgmoment";
  }
  return "Geen bezorging of afhaal mogelijk";
}

export default function DeliveryForm({
  openingHours,
  form,
  deliveryMethods,
  minimumOrderValueForDelivery,
  totalOrderValue,
}: Props): ReactElement {
  const { register, watch, errors, setValue } = form;

  const today = new Date();
  const maxDate = addDays(today, 31);
  const initialDeliveryDate = parseISO(watch().deliveryDate);

  const [deliveryDate, setDeliveryDate] = useState<Date>(initialDeliveryDate);

  useEffect(() => {
    setValue("deliveryDate", deliveryDate.toISOString());
  }, [deliveryDate, setValue]);

  const dayIndex = getDay(deliveryDate);
  const adjustedDayIndex = dayIndex === 0 ? 6 : dayIndex - 1;

  const deliveryMethod = watch("deliveryMethod");
  const { pickup: canPickup, deliver: canDeliver } = deliveryMethods;
  const canPickupAndDeliver = canPickup && canDeliver;

  const closedDatesUntilMaxDate = getClosedDatesUntilDate(openingHours, today, maxDate);

  const fromTime = openingHours[adjustedDayIndex].from;
  const startTimeForSelectedDate = parse(fromTime, "HH:mm", deliveryDate);

  const toTime = openingHours[adjustedDayIndex].to;
  const endTimeForSelectedDate = subMinutes(parse(toTime, "HH:mm", deliveryDate), 15);

  return (
    <>
      {canPickupAndDeliver && (
        <div className="mt-3">
          <label>Bezorgen of afhalen</label>
          {deliveryMethods.pickup && (
            <div>
              <input
                id="deliveryMethodPickup"
                className={`${errors.deliveryMethod ? "border-red-500" : "border-gray-300"}`}
                name="deliveryMethod"
                type="radio"
                value="pickup"
                ref={register({ required: true })}
              />
              <label className="ml-3">Afhalen</label>
            </div>
          )}
          {deliveryMethods.deliver && (
            <div>
              <input
                id="deliveryMethodDeliver"
                className={`${errors.deliveryMethod ? "border-red-500" : "border-gray-300"}`}
                name="deliveryMethod"
                type="radio"
                value="deliver"
                disabled={minimumOrderValueForDelivery > totalOrderValue}
                ref={register({ required: true })}
              />
              <label className={`ml-3 ${minimumOrderValueForDelivery > totalOrderValue ? "opacity-50" : ""}`}>
                Bezorgen
              </label>
              {totalOrderValue < minimumOrderValueForDelivery && (
                <Box>
                  <small style={{ color: "darkred" }}>
                    {`Minimum bestelbedrag voor bezorging: ${formatPrice(minimumOrderValueForDelivery)}`}
                  </small>
                </Box>
              )}
            </div>
          )}
        </div>
      )}
      {!canPickup && canDeliver && <input type="hidden" name="deliveryMethod" value="deliver" ref={register()} />}
      {!canDeliver && canPickup && <input type="hidden" name="deliveryMethod" value="pickup" ref={register()} />}

      {deliveryMethod === "deliver" && (
        <div className="flex flex-col w-full mt-3">
          <label className="mb-2">Bezorgadres</label>
          <input
            type="text"
            placeholder="Bezorgadres"
            name="address"
            ref={register({ required: true, max: 100, min: 3 })}
            className={`rounded ${errors.address ? "border-red-500 placeholder-red-500" : "border-gray-300"}`}
          />
        </div>
      )}
      <div className="mt-3">
        <label>{getDeliveryMomentLabel(canPickup, canDeliver)}</label>
        <div className="mt-3">
          <input type="hidden" ref={register()} name="deliveryDate" />
          <DatePicker
            className="form-control"
            selected={deliveryDate}
            inline
            minDate={today}
            maxDate={maxDate}
            timeIntervals={15}
            excludeDates={closedDatesUntilMaxDate}
            dropdownMode="select"
            minTime={startTimeForSelectedDate}
            maxTime={endTimeForSelectedDate}
            showTimeSelect
            locale="nl"
            dateFormat="d LLL yyyy HH:mm"
            onChange={(date: Date) => setDeliveryDate(date)}
          />
        </div>
      </div>
    </>
  );
}
