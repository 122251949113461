import { Customer } from "./customer";
import {
  addDays,
  addHours,
  addMinutes,
  differenceInCalendarDays,
  getDay,
  startOfDay,
} from "date-fns";

export interface OrderFormFields {
  name: string;
  phoneNumber: string;
  emailAddress: string;
  address?: string;
  deliveryDate: string;
  deliveryMethod: "pickup" | "deliver";
  comments: string;
}

export interface OrderRow {
  itemId: string;
  tierId?: string;
  title: string;
  amount: number;
  price: number;
  subtotal: number;
}

export interface Order {
  deliveryDate: string;
  customer: Customer;
  comments?: string;
  deliveryMethod: "pickup" | "deliver";
}

export function makeId(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function priceOfItemWithId(items, itemId, tierId) {
  const item = items.find((i) => i.id === itemId);
  if (!item) {
    return 1000000;
  }
  if (tierId === undefined) {
    return item.price.amount;
  }
  const tier = item.price.tiers.find((t) => t.id === tierId);
  if (!tier) {
    return 1000000;
  }
  return tier.amount;
}

export function calculateTotal(order: OrderRow[]): number {
  return order.reduce((total, current) => total + current.subtotal, 0);
}

export function formatPrice(amount: number): string {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(amount / 100);
}

export const getClosedDatesUntilDate = (
  openingHours: {
    from: string;
    to: string;
  }[],
  startDate: Date,
  maxDate: Date
): Date[] => {
  const dayAvailability = openingHours.map((oh) => oh.from !== oh.to);
  const diffInDays = differenceInCalendarDays(maxDate, startDate);

  const closedDatesUntilMaxDate: Date[] = [];
  for (let i = 0; i < diffInDays; i += 1) {
    const d = addDays(startDate, i);
    const dIndex = getDay(d);
    const adjustedDIndex = dIndex === 0 ? 6 : dIndex - 1;
    if (dayAvailability[adjustedDIndex] === false) {
      closedDatesUntilMaxDate.push(d);
    }
  }

  return closedDatesUntilMaxDate;
};

export const getFirstAvailableDate = (
  openingHours: {
    from: string;
    to: string;
  }[],
  startDate: Date,
  maxDate: Date
): Date | null => {
  const dayAvailability = openingHours.map((oh) => oh.from !== oh.to);
  const diffInDays = differenceInCalendarDays(maxDate, startDate);

  for (let i = 0; i < diffInDays; i += 1) {
    const day = addDays(startDate, i);
    const index = getDay(day);
    const adjustedIndex = index === 0 ? 6 : index - 1;
    if (dayAvailability[adjustedIndex] === true) {
      const openingHour = openingHours[adjustedIndex].from.split(":");
      const hoursToAdd = Number.parseInt(openingHour[0], 10);
      const minutesToAdd = Number.parseInt(openingHour[1], 10);
      const firstAvailableDate = addHours(
        addMinutes(startOfDay(day), minutesToAdd),
        hoursToAdd
      );
      return firstAvailableDate;
    }
  }

  return null;
};
