import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "@citydelivery/ui-kit/src/assets/tailwind.scss";

require("react-dom");
declare global {
  interface Window {
    React1: any;
    React2: any;
  }
}
window.React2 = require("react");
console.log(window.React1 === window.React2);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
