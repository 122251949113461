import { Type } from "class-transformer";
import {
  IsBoolean,
  IsDefined,
  IsInt,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  MaxLength,
  Min,
  MinLength,
  ValidateNested,
} from "class-validator";
import { FixedPrice, PriceTiers } from "./price";

export class MenuItem {
  @IsUUID()
  id: string;

  @IsString()
  @MinLength(3)
  @MaxLength(100)
  title: string;

  @IsDefined()
  description: string[];

  @IsDefined()
  price: FixedPrice | PriceTiers;

  @IsInt()
  sortOrder: number = 0;
}

export class MenuSection {
  @IsString()
  title: string;

  @IsOptional()
  description?: string;

  @IsOptional()
  image?: string;

  // @ValidateNested({ each: true })
  // @Type(() => MenuItem)
  items: MenuItem[];

  @IsInt()
  sortOrder: number = 0;

  @IsInt()
  @Min(0)
  @Max(5)
  columnCount: number = 0;

  @IsBoolean()
  collapsedByDefault: boolean = false;
}

export class Menu {
  @ValidateNested({ each: true })
  @Type(() => MenuSection)
  sections: MenuSection[];
}
