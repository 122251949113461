import { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { Flex } from "reflexbox";
import { useHistory } from "react-router-dom";
import Lottie, { LottieRef } from "lottie-react";
import { motion } from "framer-motion";

import { Tenant } from "@citydelivery/business-logic";

import checkAnimation from "./assets/check_animation.json";

interface Props {
  tenant: Tenant;
}

const Wrapper = styled(Flex)`
  position: fixed;
  background-color: #ffffffdd;
  width: 100%;
  height: 100vh;
`;

export default function SuccessPage({ tenant }: Props): ReactElement {
  const history = useHistory();
  const lottieRef = useRef(null);
  useEffect(() => {
    const typedRef = lottieRef as LottieRef;
    typedRef.current?.setSpeed(0.65);
    typedRef.current?.play();
  }, []);

  return (
    <Wrapper alignContent="center" justifyContent="center" alignSelf="center" flexDirection="row" style={{}}>
      <Helmet>
        <style>{"body { overflow: hidden }"}</style>
      </Helmet>
      <Flex alignContent="center" justifyContent="center" alignSelf="center" flexDirection="column" textAlign="center">
        <Lottie
          lottieRef={lottieRef}
          loop={false}
          autoPlay={false}
          style={{ width: 300, height: 300, margin: "0 auto" }}
          animationData={checkAnimation}
        />
        <motion.div
          animate={{
            scale: [0.0, 1.25, 1],
          }}
        >
          <div className="px-6 py-16 bg-gray-100 rounded" style={{ maxWidth: 500 }}>
            <h2 className="text-3xl font-semibold">Bedankt voor je bestelling!</h2>
            <p>
              Je bestelling is succesvol geplaatst. <strong>{tenant.name}</strong> neemt zo snel mogelijk contact met je
              op.
            </p>
          </div>
          <div className="mt-6">
            <button
              type="button"
              className="text-blue-500 hover:text-blue-600 hover:underline "
              onClick={() => history.push("/")}
            >
              Terug naar bestellen
            </button>
          </div>
        </motion.div>
      </Flex>
    </Wrapper>
  );
}
