import { formatPrice, MenuItem } from "@citydelivery/business-logic";
import { faMinus, faPlus, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";

interface OrderButtonProps {
  itemId: string;
  tierId?: string;
  currentAmount: number;
  onAddToCart: (itemId: string, tierId?: string) => void;
  onRemoveFromCart: (itemId: string, tierId?: string) => void;
}

function OrderButton({ itemId, tierId, currentAmount, onAddToCart, onRemoveFromCart }: OrderButtonProps) {
  if (currentAmount > 0) {
    return (
      <div>
        <div className="flex flex-row max-w-8">
          <button
            type="button"
            className="px-2 py-1 rounded-l h-8 border border-gray-400 text-gray-500 hover:bg-gray-500 hover:text-white"
            onClick={() => onRemoveFromCart(itemId, tierId)}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <input
            className="w-9 text-center bg-gray-200 h-8 border-t border-b border-gray-300"
            disabled
            value={currentAmount}
          />
          <button
            type="button"
            className="px-2 py-1 rounded-r h-8 border border-gray-400 text-gray-500 hover:bg-gray-500 hover:text-white"
            onClick={() => onAddToCart(itemId, tierId)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <button
        type="button"
        className="px-2 py-1 bg-green-500 hover:bg-green-600 h-8 rounded text-white border-left-5z"
        onClick={() => onAddToCart(itemId, tierId)}
      >
        <FontAwesomeIcon icon={faShoppingBasket} />
      </button>
    </div>
  );
}

interface MenuItemProps {
  item: MenuItem;
  shoppingCart: { [itemId: string]: { [tierId: string]: number } };
  onAddToCart: (itemId: string, tierId?: string) => void;
  onRemoveFromCart: (itemId: string, tierId?: string) => void;
}

const Description = styled.p`
  color: #555;
  margin-bottom: 15px;
`;

const ElementWrapper = styled(Flex)`
  padding: 15px 15px 0 15px;
  background-color: #f4f4f4;
`;

const MenuItemElement = ({ item, shoppingCart, onAddToCart, onRemoveFromCart }: MenuItemProps) => {
  return (
    <ElementWrapper flexGrow={1} justifyContent="space-between">
      <Box>
        <h5 className="text-xl mb-2">{item.title}</h5>
        {typeof item.description === "string" ? (
          <Description>{item.description}</Description>
        ) : (
          item.description.map((description) => <Description>{description}</Description>)
        )}
      </Box>
      <Box style={{ minWidth: 150, textAlign: "right" }}>
        {item.price.type === "fixed" && (
          <Flex mb={2} flexDirection="column" alignItems="flex-end">
            <Box mb={1}>{formatPrice(item.price.amount)}</Box>
            <OrderButton
              itemId={item.id}
              currentAmount={shoppingCart[item.id][item.id] ?? 0}
              onAddToCart={onAddToCart}
              onRemoveFromCart={onRemoveFromCart}
            />
          </Flex>
        )}
        {item.price.type === "tiers" && (
          <ul style={{ paddingLeft: 0 }}>
            {item.price.tiers.map((tier) => (
              <li style={{ listStyleType: "none" }} key={tier.id}>
                <Flex mb={3} flexDirection="column" alignItems="flex-end">
                  <Box mb={1}>
                    <strong>{tier.title}</strong>
                  </Box>
                  <Box mb={1}>{formatPrice(tier.amount)}</Box>
                  <OrderButton
                    itemId={item.id}
                    tierId={tier.id}
                    currentAmount={shoppingCart[item.id][tier.id] ?? 0}
                    onAddToCart={onAddToCart}
                    onRemoveFromCart={onRemoveFromCart}
                  />
                </Flex>
              </li>
            ))}
          </ul>
        )}
      </Box>
    </ElementWrapper>
  );
};
export default MenuItemElement;
