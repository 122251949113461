import { ReactElement, useEffect, useState } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { addDays } from "date-fns";
import DeliveryForm from "./DeliveryForm";
import { calculateTotal, OrderFormFields, OrderRow, Tenant, getFirstAvailableDate } from "@citydelivery/business-logic";

interface Props {
  tenant: Tenant;
  order: OrderRow[];
  totalOrderValue: number;
  onOrderPlaced: () => void;
}

export default function OrderForm({ order, onOrderPlaced, totalOrderValue, tenant }: Props): ReactElement {
  const [isOrdering, setIsOrdering] = useState(false);
  const firstAvailableDeliveryDate =
    getFirstAvailableDate(tenant.openingHours, new Date(), addDays(new Date(), 31)) ?? new Date();
  const form = useForm<OrderFormFields>({
    mode: "all",
    defaultValues: {
      deliveryDate: firstAvailableDeliveryDate.toISOString(),
      comments: "",
      deliveryMethod: "pickup",
      emailAddress: "",
      name: "",
      phoneNumber: "",
    },
  });
  const { register, handleSubmit, reset, formState, errors, watch } = form;
  watch();

  const onSubmit = async (data: OrderFormFields) => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    setIsOrdering(true);
    const { name, phoneNumber, emailAddress, address, deliveryDate, deliveryMethod, comments } = data;
    const tenantId = process.env.REACT_APP_HOST_OVERRIDE ?? window.location.host.split(".")[0];

    await Axios.post(`${process.env.REACT_APP_API_URL}/order`, {
      products: order,
      order: {
        customer: {
          name,
          phoneNumber,
          emailAddress,
          address,
        },
        deliveryDate,
        deliveryMethod,
        comments,
      },
      tenantId,
    });
    setIsOrdering(false);
    reset();
    onOrderPlaced();
  };

  if (!tenant.canOrder) {
    return <p>{tenant.descriptions.closed}</p>;
  }

  const isOrderButtonDisabled =
    isOrdering ||
    Object.keys(formState.errors).length > 0 ||
    (tenant.minimumOrderAmount
      ? calculateTotal(order) < tenant.minimumOrderAmount && form.getValues().deliveryMethod === "deliver"
      : false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="mb-10">{tenant.descriptions.order}</p>

      <div className="flex flex-col w-full mt-3">
        <label className="mb-2">Naam</label>
        <input
          className={`rounded ${errors.name ? "border-red-500 placeholder-red-500" : "border-gray-300"}`}
          type="text"
          placeholder="Naam"
          name="name"
          ref={register({ required: true, max: 100, min: 2 })}
        />
      </div>
      <div className="flex flex-col w-full mt-3">
        <label className="mb-2">Telefoonnummer</label>
        <input
          className={`rounded ${errors.phoneNumber ? "border-red-500 placeholder-red-500" : "border-gray-300"}`}
          type="tel"
          placeholder="Telefoonnummer"
          name="phoneNumber"
          ref={register({
            required: true,
            pattern: /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/,
          })}
        />
      </div>
      <div className="flex flex-col w-full mt-3">
        <label className="mb-2">E-mailadres</label>
        <input
          className={`rounded ${errors.emailAddress ? "border-red-500 placeholder-red-500" : "border-gray-300"}`}
          type="email"
          placeholder="E-mailadres"
          name="emailAddress"
          ref={register({ required: false })}
        />
      </div>

      <DeliveryForm
        openingHours={tenant.openingHours}
        form={form}
        deliveryMethods={tenant.deliveryMethods}
        minimumOrderValueForDelivery={tenant.minimumOrderAmount ?? 0}
        totalOrderValue={totalOrderValue}
      />

      <div className="flex flex-col w-full mt-3">
        <label className="mb-2">Opmerkingen</label>
        <textarea
          className={`rounded ${errors.comments ? "border-red-500 placeholder-red-500" : "border-gray-300"}`}
          placeholder="Opmerkingen"
          name="comments"
          rows={3}
          ref={register()}
        />
        <span className="text-xs mt-1 text-gray-300">
          Je kunt hier specifieke instructies achterlaten of vragen stellen over je bestelling.
        </span>
      </div>
      <button
        disabled={isOrderButtonDisabled || !formState.isValid}
        className={`mt-4 px-6 py-3 text-lg text-white rounded ${
          isOrderButtonDisabled || !formState.isValid
            ? "bg-gray-300"
            : "bg-green-500 hover:bg-green-400 active:bg-green-600"
        }`}
        type="submit"
      >
        Plaats bestelling
      </button>
    </form>
  );
}
