import { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Flex } from "reflexbox";
import styled from "styled-components";
import { Tenant, MenuSection } from "@citydelivery/business-logic";
import MenuItemElement from "./MenuItemElement";

interface Props {
  section: MenuSection;
  tenant: Tenant;
  shoppingCart: { [itemId: string]: { [tierId: string]: number } };
  onAddToCart: (itemId: string, tierId?: string) => void;
  onRemoveFromCart: (itemId: string, tierId?: string) => void;
}

const BannerImage = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: cover;
`;

const SectionHeader = styled.div`
  margin-top: 30px;
  cursor: pointer;
`;

const SectionHeaderContent = styled(Flex)`
  padding: 15px 0px 10px 0px;
`;

const Grid = styled.div<{ columnCount: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 30px;

  @media (max-width: 977px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default function MenuSectionTable({
  section,
  tenant,
  onAddToCart,
  onRemoveFromCart,
  shoppingCart,
}: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(!section.collapsedByDefault);
  const imgPrefix = `${process.env.REACT_APP_CONTENT_BASE_URL}/${tenant.tenantId}`;

  return (
    <div>
      <SectionHeader onClick={() => setIsExpanded(!isExpanded)}>
        {section.image && <BannerImage src={`${imgPrefix}/${section.image}`} />}
        <SectionHeaderContent flexDirection="row" justifyContent="space-between" alignItems="center">
          <div className="my-4">
            <h4 className="text-2xl font-semibold">{section.title}</h4>
            {section.description && <p>{section.description}</p>}
          </div>
          <div className="ml-6">
            <button className="px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded-md">
              <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
            </button>
          </div>
        </SectionHeaderContent>
      </SectionHeader>
      {isExpanded && (
        <Grid columnCount={section.columnCount ?? 1}>
          {section.items.map((item) => (
            <MenuItemElement
              key={item.id}
              item={item}
              onAddToCart={onAddToCart}
              onRemoveFromCart={onRemoveFromCart}
              shoppingCart={shoppingCart}
            />
          ))}
        </Grid>
      )}
    </div>
  );
}
